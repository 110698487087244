import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { defer, from, Observable, of, switchMap, tap } from 'rxjs';
import { CreateUserDto, UserResponseDto } from '../../../../../aok-backend/src/user/dto/create-user.dto';
import { env } from '../../../environments/environment';
import { PreferencesService } from '../preferences.service';
import { GetStreamService } from '../get-stream/get-stream.service';
import { UserFindConnectionResponse } from '../../../../../aok-backend/src/user/interface/user-find-connection-response.interface';

const USER_BASE_URL = `${env.apiUrl}/user`;

@Injectable({
  providedIn: 'root',
})
export class AokUserService {
  /** Temporary list of users for creating a channel */
  allUsersForNewChannel: UserResponseDto[] | undefined;

  private readonly http = inject(HttpClient);
  private readonly preferencesService = inject(PreferencesService);
  // private readonly getStreamService = inject(GetStreamService);

  createUserFromAuth0(activeUser: User): Observable<UserResponseDto> {
    if (!activeUser.sub || !activeUser.email) {
      throw `Invalid Auth0 user: ${activeUser}`;
    } else {
      const newUser: CreateUserDto = {
        authZeroId: activeUser.sub, // Must be defined
        email: activeUser.email, // Must be defined
        streamIoToken: 'to_come',
        fcmToken: 'notifications_not_set_yet',
        emailVerified: activeUser.email_verified || false,
        displayName: activeUser.name || '',
      };
      return this.createUser(newUser);
    }
  }

  private createUser(newUser: CreateUserDto): Observable<UserResponseDto> {
    console.log('🐳🐳 createUser(): ', newUser);
    newUser.authZeroId = newUser?.authZeroId ? newUser.authZeroId : 'not_found';
    return this.http.post<UserResponseDto>(USER_BASE_URL, newUser);
  }

  getUserByEmail(email: string): Observable<UserResponseDto> {
    return this.http.get<UserResponseDto>(`${USER_BASE_URL}/email/${email}`);
  }

  getUserByAuth0(sub: string): Observable<UserResponseDto> {
    return this.http.get<UserResponseDto>(`${USER_BASE_URL}/sub/${sub}`);
  }

  getUserById(userId: string): Observable<UserResponseDto> {
    return this.http.get<UserResponseDto>(`${USER_BASE_URL}/${userId}`);
  }

  updateUser(userId: string, userUpdate: Partial<UserResponseDto>) {
    return this.http.put<Partial<UserResponseDto>>(`${USER_BASE_URL}/${userId}`, userUpdate)
  }

  deleteUser$(userId: string): Observable<void> {
    console.log('Deleting user account: ', userId);
    return this.http.delete<void>(`${USER_BASE_URL}/${userId}`).pipe(
      switchMap(() => defer(() => from(this.preferencesService.resetApp()))),
      tap(() => console.log(`User account ${userId} successfully deleted`)),
      switchMap(() => of(void 0))
    )
  }

  findUserForConnection(id: string): Observable<UserFindConnectionResponse> {
    return this.http.get<UserFindConnectionResponse>(`${USER_BASE_URL}/connection-request/detail?id=${id}`);
  }

  /**
   * @returns UserResponseDto[]
   * This method is simply to get a list of user with whom
   * I can start a new chat. Probably shoulnd't keep it forever.
   */
  getAllUsers(): Observable<UserResponseDto[]> {
    return this.http.get<UserResponseDto[]>(`${USER_BASE_URL}`);
  }
}
